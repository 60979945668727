.card-amount {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.react-daterange-picker__wrapper {
  padding: 5px !important;
  border-radius: 5px;
  border-color: hsl(0, 0%, 80%);
}

.data-table-extensions {
  background-color: #fff;
  margin-top: 20px;
}
