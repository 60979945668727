.banner-img img {
  width: 170px;
}

.banner-big img {
  width: 700px;
  /* height: 400px; */
}

@media (max-width: 768px) {
  .banner-img img {
    width: 550px;
    height: 200px;
  }
  .banner-big img {
    width: 380px;
  }
}
