.chatSection {
    position: fixed;
    bottom: 50px;
    height: 80vh;
    right: 100px;
    border: 2px solid #f5921e;
    z-index: 9;
    width: 35%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    padding: 0;
    background-color: white;
}
.home-main-cont{
    margin: 0 10%;
}
.chat-cont{
    right: 11%;
}
.hover-cont:hover{
    background-color: rgb(212, 212, 212);
    
}
@media only screen and (max-width: 600px) {
    .nav{
        padding-right: 30px;
    }
    .chatSection {
        width: 85%;
        bottom: 100px;
        right: 30px;
        height: 70vh;
    }
    .home-main-cont{
        margin: 0 5%;
    }
    .chat-cont{
        right: 7%;
    }
}
