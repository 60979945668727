.text {
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 10px;
    position: relative;
    padding-left: 15px;
  }
  
  .text::before {
    transform: translateX(-50%);
    border-radius: 100%;
    position: absolute;
    background: rgba(104, 104, 104, 1);
    bottom: 16px;
    height: 6px;
    content: '';
    width: 6px;
    left: 0;
  }