.img-wrap {
  position: relative;
}
.img-wrap .close {
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
  color: red;
  background-color: rgb(212, 212, 212);
  padding: 3px 6px;
  border-bottom-left-radius: 10px;
}
