.editorClass{
    min-height: 400px;
}

.header-image{
    width: 75%;
    height: 500px;
}

@media only screen and (max-width: 600px) {
    .header-image{
        width: 100%;
        height: 350px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
    .header-image{
        width: 100%;
        height: 350px;
    }
} */