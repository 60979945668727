.cover-image{
    max-height: 24rem !important;
}

@media (max-width: 768px){
    /* .cover-image{
        max-height: 24rem !important;
    } */

    .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
        border: none !important;
    }
}