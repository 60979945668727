.text-wrap {
  /* width: 200px; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.text-wrap-title {
  /* width: 200px; */
  height: 50px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.meta-wrap {
  height: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.testPurple {
  /* background: rgba(253, 249, 241, 1) !important; */
  max-width: 25%;
  z-index: 99999999 !important;
}

.tooltip {
  position: relative;
  /* display: inline-block; */
}

/* Tooltip text */
.tooltiptext {
  visibility: visible;
  width: 120px;
  background-color: rgb(151, 151, 151);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  z-index: 1000;
  opacity: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(151, 151, 151) transparent transparent transparent;
}

.tooltip .tooltiptext {
  opacity: 0;
  transition: opacity 1s;
}

.tooltip:hover .tooltiptext {
  opacity: 1;
}

.buttons {
  background-color: #ffffff;
}

.home-main-cont {
}

.accordion-item {
  padding: 0;
}

.accordion-item .accordion-button {
  outline: none;
  border-radius: 4px;
  background: #f8f8f8;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  padding-top: 18px;
  padding-left: 30px;
  padding-right: 30px;
}

.accordion-item .accordion-button:focus {
  box-shadow: none;
}

.accordion-item .accordion-button .collapsed {
  border-radius: 4px;
}

.accordion-item .accordion-body {
  padding: 0;
}

@media only screen and (max-width: 768px) {
  .join-live-class {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.rotate-90 {
  transform: rotate(180deg) !important;
}

.rotate-180 {
  transform: rotate(0deg) !important;
}

.banner-diet img {
  width: 150px;
}

.yoga-logo img {
  width: 150px;
}

@media (max-width: 768px) {
  .banner-diet img {
    width: 550px;
    height: 200px;
  }

  .yoga-logo img {
    width: 250px;
  }
}
