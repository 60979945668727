.x-glowing-ring-1 {
    background: linear-gradient(180deg, #F9D145 0%, #F19743 100%);
    opacity: 0.1;
    z-index: 0;
}

.x-glowing-ring-2 {
    background: linear-gradient(180deg, #F9D145 0%, #F19743 100%);
    opacity: 0.3;
    z-index: 1;
    transform: scale(0.9);
}

.x-glowing-ring-3 {
    background: linear-gradient(180deg, #F9D145 0%, #F19743 100%);
    z-index: 3;
    transform: scale(0.8);
}

.x-glowing-ring-4 {
    background: white;
    z-index: 4;
    transform: scale(0.75);
}

.x-ring {
    border-radius: 100%;
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
}
