.yoga {
  font-family: "Merriweather" !important;
}
.review-wid {
  border: 1px solid #000;
  padding: 10px;
  min-width: 280px;
}

.review-name {
  color: #000;
  font-family: "Merriweather" !important;
  /* font-size: 18px; */
  font-style: italic;
  font-weight: 400;
}

.review-desc {
  color: #000;
  font-family: "Merriweather" !important;
  /* font-size: 17px !important; */
  font-style: normal;
  font-weight: 400;
}

.title {
  font-family: "Merriweather" !important;
  font-size: 26px;
  margin-top: 20px;
  padding-bottom: 10px;
}

.fieldTitle {
  font-family: "Merriweather" !important;
  font-size: 16px;
}

.youtubeFrame {
  display: flex;
  justify-content: center;
  align-items: center;
}
.youtubeFrame iframe {
  width: 80%;
  height: 500px;
  margin-top: 30px;
}

@media only screen and (max-width: 768px) {
  .youtubeFrame iframe {
    width: 100%;
    height: 350px;
  }
}
