.head-text {
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 10px;
}

.body-text {
  font-size: 14px;
  padding-bottom: 15px;
}
