.x-login-modal {
  height: 100%;
}

@media (min-width: 640px) {
  .x-login-modal {
    height: auto;
    min-height: 550px;
  }
}
