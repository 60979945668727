.x-profile-modal {
    height: 100%;
  }
  
  @media (min-width: 640px) {
    .x-profile-modal {
      height: auto;
      min-height: 550px;
    }
  }
  