@import "~react-image-gallery/styles/css/image-gallery.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.blog-padding {
  padding: 10px 0 !important;
  margin: 10px 0 !important;
}

.dd {
  @apply bg-red-500;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

*::-webkit-scrollbar {
  display: none;
}

.ticked {
  background: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
}

html {
  scroll-behavior: smooth;
}
