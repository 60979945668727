.landing-girl-animate {
  animation: landing-levitate 4s ease-in-out infinite;
}

.landing-shadow-animate {
  animation: landing-shadow 4s ease-in-out infinite;
}

@keyframes landing-levitate {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.landing-girl-new-animate {
  animation: landing-levitate-new 4s ease-in-out infinite;
}

@keyframes landing-levitate-new {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes landing-shadow {
  0% {
    transform: scaleX(0.7);
    opacity: 1;
  }
  50% {
    transform: scaleX(1);
    opacity: 0.5;
  }
  100% {
    transform: scaleX(0.7);
    opacity: 1;
  }
}
